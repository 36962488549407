import React, { useState, useEffect, useRef, useContext } from 'react';
import { ChatContext } from '../ChatContext';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";

const Messages = () => {
    const { messages } = useContext(ChatContext);
    const [formattedMessages, setFormattedMessages] = useState([]);
    const [copySuccess, setCopySuccess] = useState(''); // To track which code block was copied
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        const updatedMessages = messages.map(msg => {
            const segments = msg?.content.split(/(```)/);
            let inCodeBlock = false;
            let language = "text"; // Default language
            const formattedSegments = segments.map((segment, index) => {
                if (segment === '```') {
                    inCodeBlock = !inCodeBlock;
                    return null;
                }
                if (inCodeBlock) {
                    const firstLineBreak = segment.indexOf('\n');
                    if (firstLineBreak !== -1) {
                        language = segment.slice(0, firstLineBreak).trim();
                        segment = segment.slice(firstLineBreak + 1);
                    }
                    return (
                        <div className="code-block" key={index}>
                            <div className="code-header">
                                <span>{language.toUpperCase()}</span>
                                <CopyToClipboard text={segment}
                                    onCopy={() => {
                                        setCopySuccess(index); // Set the index of the copied block
                                        setTimeout(() => setCopySuccess(''), 2000); // Reset after 2 seconds
                                    }}>
                                    <button aria-label="Copy Code" className={copySuccess === index ? 'copied' : ''}>
                                        <MdContentCopy />
                                    </button>
                                </CopyToClipboard>
                            </div>
                            <SyntaxHighlighter language={language} style={vscDarkPlus}>
                                {segment}
                            </SyntaxHighlighter>
                        </div>
                    );
                } else {
                    return segment;
                }
            });

            return {
                ...msg,
                formattedContent: formattedSegments.filter(segment => segment !== null)
            };
        });
        setFormattedMessages(updatedMessages);
    }, [messages]);

    return (
        <div className="messages">
            <div className="spacer"></div>
            {formattedMessages.map((msg, index) => (
                <div 
                    key={index} 
                    className={`message ${
                        msg?.role === 'user' ? 'user-message' :
                        msg?.role === 'session-summary' ? 'session-summary' : 'bot-message'
                    }`}
                >
                    {msg.formattedContent.map((content, subIndex) => (
                        <React.Fragment key={subIndex}>
                            {content}
                        </React.Fragment>
                    ))}
                </div>
            ))}
            <div ref={endOfMessagesRef} />
        </div>
    );    
};

export default Messages;
