import React, { useState, useEffect, useRef } from 'react';
import { MdFavoriteBorder, MdFavorite, MdSubdirectoryArrowRight, MdOutlinePlaylistAdd, MdInput, MdMoreHoriz } from 'react-icons/md';
import { categories } from '../ChatContext';

const SessionButtons = ({ category, onFavorite, onRenameStart, onDelete, onLoad, onAppend, onLoadPrompt, onCategoryChange, isFavorite }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const menuRef = useRef(null);

    const commonButtons = (
        <>
            <button className="menu-button" onClick={() => setShowMenu(!showMenu)} title="More Actions">
                <MdMoreHoriz />
            </button>
            <button className="favorite-button" onClick={onFavorite} title={isFavorite ? "Unfavorite" : "Favorite"}>
                {isFavorite ? <MdFavorite /> : <MdFavoriteBorder />}
            </button>
        </>
    );

    const categorySpecificButtons = () => {
        switch (category) {
            case 'Blue':
                return (
                    <>
                        {commonButtons}
                        <button className="load-prompt-button" onClick={onLoadPrompt} title="Load Prompt to Input">
                            <MdInput />
                        </button>
                    </>
                );
            default:
                return (
                    <>
                        <button className="append-content-button" onClick={onAppend} title="Append Content">
                            <MdOutlinePlaylistAdd />
                        </button>
                        {commonButtons}
                        <button className="load-session-button" onClick={onLoad} title="Load Session">
                            <MdSubdirectoryArrowRight />
                        </button>
                    </>
                );
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
                setShowConfirm(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const handleCategoryChange = (e) => {
        onCategoryChange(e);
        setShowMenu(false); // Close the menu after selection
    };

    return (
        <div className="session-actions" style={{ position: 'relative' }}>
            {categorySpecificButtons()}
            {showMenu && (
                <div className="menu-dropdown" ref={menuRef}>
                    <button onClick={onRenameStart}>Rename</button>
                    <button onClick={() => setShowConfirm(true)}>Delete</button>
                    {showConfirm && (
                        <div className="confirmation-dialog">
                            <p>Are you sure you want to delete this session? This action cannot be undone.</p>
                            <button onClick={onDelete}>Yes</button>
                            <button onClick={() => setShowConfirm(false)}>No</button>
                        </div>
                    )}
                    <select onChange={handleCategoryChange} value={category}>
                        <option value="None">Select Category</option>
                        {Object.keys(categories).map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default SessionButtons;
