import React, { useState, useEffect, useRef } from 'react';
import { useChat, sessionModel, categories } from '../ChatContext';
import SessionButtons from './ChatSessionButtons'; // Import the helper component

const ChatSession = ({ session }) => {
    const { updateSession, removeSessionFromList, activeSessionId, loadSession, appendSession, loadPrompt } = useChat();
    const isInUse = session.id === activeSessionId;
    const [currentSession, setCurrentSession] = useState({ ...sessionModel, ...session });
    const [isFavorite, setIsFavorite] = useState(session.isFavorite);
    const [isEditing, setIsEditing] = useState(false);
    const [editedSubject, setEditedSubject] = useState(session.subject);
    const [borderColor, setBorderColor] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);

    const categoryBorderColors = {
        Red: '#E36259',
        Green: '#2E8B57',
        Blue: '#4169E1',
        Yellow: '#FFD700',
        Purple: '#9932CC',
        Orange: '#FF8C00',
        None: '#3b3b3b',
    };

    useEffect(() => {
        setBorderColor(categoryBorderColors[currentSession.category] || categoryBorderColors['None']);
    }, [currentSession.category]);

    const toggleFavorite = (e, sessionId) => {
        e.stopPropagation();
        const newIsFavorite = !isFavorite;
        setIsFavorite(newIsFavorite);
        const updatedSession = { ...currentSession, isFavorite: newIsFavorite };
        updateSession(sessionId, updatedSession);
        setCurrentSession(updatedSession);
    };

    const handleEditStart = () => {
        setIsEditing(true);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    };

    const handleRenameStart = () => {
        setIsEditing(true);
        setTimeout(() => {
            inputRef.current && inputRef.current.focus();
        }, 100);
    };

    const handleEditChange = (e) => {
        setEditedSubject(e.target.value);
    };

    const handleEditSave = async () => {
        if (editedSubject !== session.subject) {
            console.log(`Updating session subject to: ${editedSubject}`);
            const updatedSession = { ...currentSession, subject: editedSubject };
            updateSession(updatedSession.id, updatedSession);
            setCurrentSession(updatedSession);
            setIsEditing(false);
        } else {
            setIsEditing(false);
        }
    };

    const handleEditCancel = () => {
        setEditedSubject(session.subject);
        setIsEditing(false);
    };

    const handleSessionSelect = async (e, sessionId) => {
        e.stopPropagation();
    };

    const handleSessionLoad = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        try {
            await loadSession(currentSession.id);
        } catch (error) {
            console.error("Error loading session:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleContentAppend = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        console.log("Appending session content");
        try {
            await appendSession(currentSession.id, activeSessionId);
        } catch (error) {
            console.error("Error loading session:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoadPrompt = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        try {
            await loadPrompt(currentSession.id);
        } catch (error) {
            console.error("Error loading prompt:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = () => {
        const updatedSession = { ...currentSession, isActive: false };
        updateSession(currentSession.id, updatedSession).then(() => {
            removeSessionFromList(currentSession.id);
            setCurrentSession(sessionModel);
        }).catch(error => {
            console.error("Failed to delete session:", error);
        });
    };

    const handleCategoryChange = (e) => {
        const newCategory = e.target.value;
        const updatedSession = { ...currentSession, category: newCategory };
        updateSession(updatedSession.id, updatedSession);
        setCurrentSession(updatedSession);
    };

    function hexToRgb(hex) {
        if (!hex) return '0,0,0';
        let r = 0, g = 0, b = 0;
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
        } else if (hex.length === 6) {
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
        }
        return `${r},${g},${b}`;
    }

    const displayText = currentSession.subject || new Date(currentSession.start_time).toLocaleString();

    return (
        <div className="chat-session" style={{
            borderColor: borderColor,
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor: isInUse ? `rgba(${hexToRgb(borderColor)}, 0.3)` : 'transparent',
            opacity: session.isActive ? 1 : 0.5
        }} onClick={(e) => handleSessionSelect(e, session.id)}>
            {isLoading ? (
                <div className="loading-indicator">Loading session...</div>
            ) : (
                <>
                <div className="text-container">
                    {isEditing ? (
                        <input
                            ref={inputRef}
                            type="text"
                            value={editedSubject}
                            onChange={handleEditChange}
                            onBlur={handleEditSave}
                            onKeyDown={(e) => { if (e.key === 'Enter') handleEditSave(); }}
                        />
                    ) : (
                        <h3 onDoubleClick={handleEditStart}>{displayText}</h3>
                    )}
                </div>
                <SessionButtons
                    category={currentSession.category}
                    onFavorite={(e) => toggleFavorite(e, currentSession.id)}
                    onRenameStart={handleRenameStart}
                    onDelete={handleDelete}
                    onLoad={handleSessionLoad}
                    onAppend={handleContentAppend}
                    onLoadPrompt={handleLoadPrompt}
                    onCategoryChange={handleCategoryChange}
                    isFavorite={isFavorite}
                />
                </>
            )}
        </div>
    );
};

export default ChatSession;
