import React from 'react';
import ChatSession from './ChatSession';
import { useContext } from 'react';
import { ChatContext } from '../ChatContext';

const ChatSessionsList = () => {
    const { sessions, selectedCategories, searchTerm } = useContext(ChatContext);
    //console.log("Selected Categories:", selectedCategories);
    //console.log("Sessions:", sessions.map(s => `${s.id}: ${s.category}`));
    
    return (
        <div className="sessions-container">
            {sessions
                .filter(session => {
                    const isIncluded = selectedCategories.includes(session.category);
                    //console.log(`Session ${session.id} with category ${session.category} included: ${isIncluded}`);
                    return isIncluded;
                })
                .filter(session => {
                    const subject = session.subject || ''; // Ensure subject is a string
                    return subject.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .map(session => (
                    <ChatSession
                        key={session.id}
                        session={session}
                    />
                ))
            }
        </div>
    );
};

export default ChatSessionsList;
