import React, { useState, useContext } from 'react';
import { ChatContext } from '../ChatContext';
import { MdAutorenew, MdOutlineSummarize } from "react-icons/md";
import CategoryFilter from './CategoryFilter'; // Make sure the path is correct

const ChatHeader = () => {
    const { resetSession, setModel, model, activeSessionId, SummarizeSession, categories, setSearchTerm } = useContext(ChatContext);
    const [selectedModel, setSelectedModel] = useState(model);
    const [search, setSearch] = useState('');

    const onSummarizeSession = async () => {
        if (activeSessionId && selectedModel) {
            await SummarizeSession(activeSessionId, selectedModel);
        } else {
            console.error("Active session ID or model is undefined.");
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearch(searchTerm);
        setSearchTerm(searchTerm);
    };

    const handleCategorySelection = (selectedCategories) => {
        console.log("Selected Categories:", selectedCategories);
        // Further filtering logic can go here based on selectedCategories
    };

    return (
        <div className="chat-header-wrapper">
            <div className="search-and-controls">
                <input
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <div className="control-buttons">
                    <button onClick={resetSession} className="reset-chat-button" title="Reset Session">
                        <MdAutorenew />
                    </button>
                    <button onClick={onSummarizeSession} className="save-session-button" title="Summarize Session">
                        <MdOutlineSummarize />
                    </button>
                </div>
            </div>
            <CategoryFilter onCategoryChange={handleCategorySelection} />
        </div>
    );
};

export default ChatHeader;
