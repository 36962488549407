import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../ChatContext';
import Messages from './Messages';
import InputBox from './InputBox';

const ChatBox = () => {
    const { messages, sendMessage, wordCount, activeSessionId, model, setModel, loadPrompt, inputPrompt } = useContext(ChatContext);
    const [selectedModel, setSelectedModel] = useState(model);

    const models = ["gpt-4o", "gpt-4", "gpt-3.5-turbo-0125", "gpt-4-turbo", "Gemini"];

    const handleSendMessage = async (message) => {
        await sendMessage(message, activeSessionId, selectedModel); // Delegate the send message logic to context
    };

    const handleModelChange = (event) => {
        const newModel = event.target.value;
        setSelectedModel(newModel);
        setModel(newModel);
    };

    return (
        <div className="chat-box">
            <div className="messages-container">
                <Messages messages={messages} />
            </div>
            <div className="info-bar"> {/* Added wrapper for word count and model dropdown */}
                <div className="word-count">
                    Total Words: {wordCount}
                </div>
                <select value={selectedModel} onChange={handleModelChange} className="model-dropdown">
                    {models.map(model => (
                        <option key={model} value={model}>{model}</option>
                    ))}
                </select>
            </div>
            <div className="input-box-container">
                <InputBox onSendMessage={handleSendMessage} initialPrompt={inputPrompt} />
            </div>
        </div>
    );
};

export default ChatBox;
