import React, { useContext, useEffect } from 'react';
import '../SessionHeader.css';
import { MdDoneAll } from "react-icons/md";
import { ChatContext } from '../ChatContext';

const CategoryFilter = ({ onCategoryChange }) => {
    const { categories, selectedCategories, setSelectedCategories } = useContext(ChatContext);

/*
    useEffect(() => {
        // Ensure all categories are selected when the component mounts
        const allCategories = Object.keys(categories);
        if (selectedCategories.length !== allCategories.length) {
            setSelectedCategories(allCategories);
            onCategoryChange(allCategories);
        }
    }, [categories, selectedCategories, setSelectedCategories, onCategoryChange]);
*/
    const handleCategoryChange = (category) => {
        const currentIndex = selectedCategories.indexOf(category);
        let newSelectedCategories = [...selectedCategories];

        if (currentIndex === -1) {
            newSelectedCategories.push(category);
        } else {
            newSelectedCategories.splice(currentIndex, 1);
        }

        setSelectedCategories(newSelectedCategories);
        onCategoryChange(newSelectedCategories);
    };

    const toggleAllCategories = () => {
        let newSelectedCategories;
        if (selectedCategories.length === Object.keys(categories).length) {
            newSelectedCategories = []; // Deselect all
        } else {
            newSelectedCategories = Object.keys(categories); // Select all
        }
        setSelectedCategories(newSelectedCategories);
        onCategoryChange(newSelectedCategories);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button onClick={toggleAllCategories} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <MdDoneAll size={18} color="white" title="Toggle All"/>
            </button>
            {Object.keys(categories).map(category => (
                <label key={category} style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        style={{ display: 'none' }}
                        checked={selectedCategories.includes(category)}
                        onChange={() => handleCategoryChange(category)}
                    />
                    <span
                        className="category-checkbox"
                        style={{
                            backgroundColor: selectedCategories.includes(category) ? categories[category] : 'transparent',
                            border: `2px solid ${categories[category]}`
                        }}
                    />
                </label>
            ))}
        </div>
    );
};

export default CategoryFilter;
