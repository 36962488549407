import React, { createContext, useContext, useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

// Define a basic session model structure
export const sessionModel = {
    id: null,
    start_time: null,
    subject: '',
    isFavorite: false,
    isActive: true,
    isRetrievalSource: false,
    category: 'None'
};

export const categories = {
    Red: '#E36259',
    Green: '#2E8B57',
    Blue: '#4169E1',
    Yellow: '#FFD700',
    Purple: '#9932CC',
    Orange: '#FF8C00',
    None: '#3b3b3b'
};

const defaultContext = {
    messages: [],
    wordCount: 0,
    sendMessage: async () => { throw new Error("sendMessage function not implemented"); },
    resetSession: () => {},
    updateSession: () => {},
    isLoading: false,
    setIsLoading: () => {},
    model: 'gpt-4',
    setModel: (model) => {},
    sessions: [],
    fetchSessions: async () => { throw new Error("fetchSessions function not implemented"); },
    activeSessionId: null, // Default to no active session
    setActiveSessionId: () => {}, // Function to update the active session
    SummarizeSession: async () => { throw new Error("SummarizeSession function not implemented"); },
    selectedCategories: [], // Initialize with empty array
    setSelectedCategories: () => {}, // Function to update selected categories
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    logout: () => {},
    categories,
    searchTerm: '',
    setSearchTerm: () => {},
};
export const ChatContext = createContext(defaultContext);

// Example sessions data
const sampleSessions = [
    {
      id: '1',
      subject: 'Session One',
      category: 'None',
      isFavorite: false,
      isActive: true
    },
    {
      id: '2',
      subject: 'Session Two',
      category: 'Red',
      isFavorite: true,
      isActive: true
    },
    {
      id: '3',
      subject: 'Session Three',
      category: 'Green',
      isFavorite: false,
      isActive: true
    }
];

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [sessions, setSessions] = useState(sampleSessions);
    const [messages, setMessages] = useState([]);
    const [wordCount, setWordCount] = useState(0);  // Initialize word count state
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [model, setModel] = useState('gpt-4'); // Default model
    const [isLoading, setIsLoading] = useState(false); 
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [inputPrompt, setInputPrompt] = useState('');
    const [promptLoaded, setPromptLoaded] = useState(false);

    // Extract unique categories from sessions
    const allCategories = Object.keys(categories);

    // Initialize selectedCategories with all categories
    const [selectedCategories, setSelectedCategories] = useState(allCategories);
    const [searchTerm, setSearchTerm] = useState(''); // Add this line to initialize searchTerm state


    useEffect(() => {
        // Ensure all categories are selected when the component mounts
        const allCategories = Object.keys(categories);
        if (selectedCategories.length !== allCategories.length) {
           // setSelectedCategories(allCategories);
        }
    }, [categories]); // Dependency array to ensure this runs when categories change

    const changeModel = (newModel) => {
        console.log('Changing model in Context to:', newModel); // Debug output
        setModel(newModel); // Call the original setter function
    };

    const fetchSessions = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/get_sessions', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                const formattedSessions = data.sessions.map(session => ({
                    ...sessionModel, // Defaults from model
                    ...session // Overridden with actual data from the API
                }));
    
                // Sort sessions by isFavorite true first and then by start_time descending
                formattedSessions.sort((a, b) => {
                    if (a.isFavorite === b.isFavorite) {
                        // If both sessions have the same favorite status, sort by start_time
                        return new Date(b.start_time) - new Date(a.start_time);
                    }
                    // Sort so that favorites always come first
                    return a.isFavorite ? -1 : 1;
                });
                setSessions(formattedSessions);
            } else {
                console.error("Error fetching sessions");
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch sessions:", error);
            setIsLoading(false);
        }
    };

    const loadSession = async (sessionId) => {
        setIsLoading(true);
        setActiveSessionId(sessionId);

        const token = localStorage.getItem('token');
        // Include the session ID in the body of the request
        const response = await fetch('/api/load_session', {
            method: 'POST', // Should be POST if you are sending data
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ sessionId }), // Send the sessionId to the server
        });

        if (response.ok) {
            const data = await response.json();
            // Set the messages to the loaded session messages
            setMessages(data.messages); 
            console.log(data.messages); // Check the structure and content

            // You might also want to update the word count based on the loaded messages
            const loadedWordCount = data.messages.reduce((count, message) => count + message.content.split(' ').length, 0);
            setWordCount(loadedWordCount);
            setIsLoading(false);
        } else {
            console.error("Error loading session");
            setIsLoading(false);
        }
    };

    const appendSession = async (sourceSessionId, targetSessionId) => {
        setIsLoading(true);  // Start loading
    
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
    
        // Make a POST request to the new 'append_session' endpoint
        const response = await fetch('/api/append_session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ sourceSessionId, targetSessionId }), // Send both session IDs to the server
        });
    
        if (response.ok) {
            const data = await response.json();
            console.log("Session content appended successfully:", data);
    
            // Optionally, load the target session to view the updated content
            await loadSession(targetSessionId);  // Re-use the existing loadSession function to update the UI
    
            setIsLoading(false);  // Stop loading
        } else {
            console.error("Error appending session content");
            setIsLoading(false);  // Stop loading
        }
    };

    const updateSession = async (sessionId, sessionData) => {
        const token = localStorage.getItem('token');
        console.log(sessionData)
        const response = await fetch(`/api/update_session/${sessionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,  // Replace with your actual method of handling auth
            },
            body: JSON.stringify(sessionData)
        });
    
        if (!response.ok) {
            throw new Error('Failed to update session');
        }
    
        const data = await response.json();
        console.log("Updated session data:", data);  // Debugging output

        // Update the session in the local state
        setSessions(prevSessions => prevSessions.map(session => 
            session.id === sessionId ? { ...session, ...sessionData } : session
        ));

        return data;
    };

    const removeSessionFromList = sessionId => {
        setSessions(prevSessions => prevSessions.filter(session => session.id !== sessionId && session.isActive));
    };

    const sendMessage = (text, sessionId, model) => {
        const token = localStorage.getItem('token');

        console.log("Session ID:", sessionId);
        console.log("Model:", model);

        setIsLoading(true);

        const userMessage = {
            id: Date.now(),
            content: text,  // The text the user sent
            role: 'user',   // Optional, if you distinguish between user and bot messages
            complete: true  // User message doesn't need to be appended to, so it's complete
        };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        const newMessageId = Date.now(); // This should be globally unique ideally.
        setMessages(prevMessages => [...prevMessages, { id: newMessageId, content: '', complete: false }]);

        // Encode the token directly in the URL
        const url = `/api/chat_response?token=${encodeURIComponent(token)}&message=${encodeURIComponent(text)}&sessionId=${encodeURIComponent(sessionId)}&model=${encodeURIComponent(model)}`;
        const eventSource = new EventSource(url);
        eventSource.onmessage = function(event) {
            const data = JSON.parse(event.data);
            unstable_batchedUpdates(() => {
                setWordCount(data.totalWordCount);
                if (!sessionId && data.sessionId) {
                    setActiveSessionId(data.sessionId);
                }
                setMessages(prevMessages => {
                    const lastIndex = prevMessages.length - 1;
                    const lastMessage = prevMessages[lastIndex];
                    if (lastMessage.id === newMessageId) {
                        lastMessage.content += data.content;
                        return [...prevMessages.slice(0, lastIndex), lastMessage];
                    }
                    return prevMessages;
                });
            });
        };

        eventSource.addEventListener('endOfStream', event => {
            console.log('Received endOfStream event:', event);
            eventSource.close();
            setIsLoading(false);
            setMessages(prevMessages => {
                const lastIndex = prevMessages.length - 1;
                const lastMessage = prevMessages[lastIndex];
                if (lastMessage.id === newMessageId) {
                    lastMessage.complete = true; // Marking the message as complete
                    return [...prevMessages.slice(0, lastIndex), lastMessage];
                }
                return prevMessages;
            });
        });

        eventSource.onerror = function(event) {
            console.error('EventSource failed:', event);
            if (event.currentTarget.readyState === EventSource.CLOSED) {
                console.log('Stream closed normally.');
            } else {
                console.log('Stream closed due to error.');
            }
            eventSource.close();
            setIsLoading(false);
            setMessages(prevMessages => prevMessages.map(message =>
                message.id === newMessageId ? { ...message, complete: true } : message
            ));
            setActiveSessionId(data.sessionId);
        };

        eventSource.onopen = function() {
            console.log('Stream opened');
        };
    };

    const SummarizeSession = (sessionId, model) => {
        const token = localStorage.getItem('token');
        console.log("Session ID:", sessionId);
        console.log("Model:", model);

        if (!sessionId || !model) {
            console.error("Session ID or model is undefined.");
            setIsLoading(false);
            return; // Stop execution if sessionId or model is not provided
        }

        setIsLoading(true);
        const newMessageId = Date.now();

        const url = `/api/create_summary?sessionId=${encodeURIComponent(sessionId)}&model=${encodeURIComponent(model)}&token=${encodeURIComponent(token)}`;

        const eventSource = new EventSource(url);

        eventSource.onmessage = function(event) {
            const data = JSON.parse(event.data);
            console.log(data);  // Debugging output

            if (data.firstMessage) {
                console.log("Received initial setup data:", data);
                const newSession = {
                    ...sessionModel,
                    id: data.sessionId,
                    start_time: data.start_time,
                    subject: "Summary",
                    category: "Green"
                };
                setSessions(prevSessions => [newSession, ...prevSessions]);
                setActiveSessionId(data.sessionId);
                setMessages([]);
            } else {
                console.log("Junk arrived:", data.content);
                setWordCount(data.totalWordCount);
                setMessages(prevMessages => {
                    if (prevMessages.length === 0) {
                        return [{ id: newMessageId, content: data.content, role: "session-summary", complete: false }];
                    }
                    return prevMessages.map(message => {
                        if (message.id === newMessageId) {
                            return { ...message, content: message.content + data.content };
                        }
                        return message;
                    });
                });
            }
        };

        eventSource.onerror = function(event) {
            console.error('EventSource failed:', event);
            eventSource.close();
            setIsLoading(false);
        };

        eventSource.addEventListener('endOfStream', event => {
            console.log('Received endOfStream event:', event);
            eventSource.close();
            setIsLoading(false);
            setMessages(prevMessages => {
                if (prevMessages.length === 0) {
                    console.error("No messages to complete.");
                    return [];
                }
                const lastIndex = prevMessages.length - 1;
                const lastMessage = prevMessages[lastIndex];
                if (lastMessage.id === newMessageId) {
                    lastMessage.complete = true;
                    return [...prevMessages.slice(0, lastIndex), lastMessage];
                }
                return prevMessages;
            });
        });
    };

    const resetSession = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/reset_chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            if (response.ok) {
                const newSession = {
                    ...sessionModel,
                    id: data.sessionId,
                    start_time: data.start_time
                };
                setSessions(prevSessions => [newSession, ...prevSessions]);
                setActiveSessionId(data.sessionId);
                setMessages([]);
                setWordCount(0);
                setIsLoading(false);
            } else {
                console.error("Failed to reset chat:", data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error resetting chat:", error);
            setIsLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsAuthenticated(false);
        window.location.href = '/login';
    };

    const loadPrompt = async (sessionId) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/load_prompt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ sessionId }), // Send the sessionId to the server
            });
    
            if (response.ok) {
                const data = await response.json();
                const lastUserMessage = data.lastUserMessage;
                setInputPrompt(lastUserMessage ? lastUserMessage.content : '');

                setPromptLoaded(true); // Mark prompt as loaded
                console.log(lastUserMessage);
            } else {
                console.error("Error loading prompt");
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to load prompt:", error);
            setIsLoading(false);
        }
    };
    
    

    const value = {
        messages,
        wordCount,
        sendMessage,
        isLoading,
        setIsLoading,
        loadSession,
        appendSession,
        resetSession,
        updateSession,
        removeSessionFromList,
        model,
        setModel: changeModel,
        sessions,
        fetchSessions,
        activeSessionId,
        setActiveSessionId,
        SummarizeSession,
        selectedCategories,
        setSelectedCategories,
        isAuthenticated,
        setIsAuthenticated,
        logout,
        categories,
        searchTerm, // Make sure these are initialized and used
        setSearchTerm, // Make sure these are initialized and used
        loadPrompt, // Add this line
        inputPrompt, // Add this line
        setInputPrompt, // Add this line
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};
