import React, { useState, useRef, useContext, useLayoutEffect, useEffect } from 'react';
import { ChatContext } from '../ChatContext';

const InputBox = ({ onSendMessage }) => {
    const [message, setMessage] = useState('');
    const { isLoading, messages, inputPrompt } = useContext(ChatContext);
    const textareaRef = useRef(null); // Reference to the textarea element

    useEffect(() => {
        if (!isLoading) {
            textareaRef.current.focus(); // Refocus the textarea whenever not loading
        }
    }, [isLoading, messages]);

    useEffect(() => {
        if (inputPrompt) {
            setMessage(inputPrompt);
            // Delay the height adjustment to ensure the DOM has updated
            setTimeout(() => {
                adjustTextareaHeight();
            }, 0);
        }
    }, [inputPrompt]);

    const handleChange = (event) => {
        setMessage(event.target.value);
        adjustTextareaHeight();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!message.trim()) return;
        onSendMessage(message.trim());
        setMessage('');
        adjustTextareaHeight(true);
        textareaRef.current.focus();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const adjustTextareaHeight = (reset = false) => {
        const textarea = textareaRef.current;
        if (!textarea) return;
        const lineHeight = 19; // Example line height in pixels. Adjust as needed.
        if (reset) {
            textarea.style.height = `${lineHeight}px`; // Set to default height for one line.
        } else {
            textarea.style.height = 'auto'; // Reset height to recalculate
            textarea.style.height = `${Math.max(textarea.scrollHeight - lineHeight, lineHeight)}px`;
        }
    };

    return (
        <form className="input-box" onSubmit={handleSubmit}>
            <textarea
                ref={textareaRef}
                className="text-input"
                value={isLoading ? "Processing your message..." : message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Type a prompt..."
                rows="1"
                disabled={isLoading}  // Disable input when loading
            />
            <button type="submit" className="send-button" aria-label="Send message" disabled={isLoading}>➤</button>
        </form>
    );
};

export default InputBox;
