import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import ChatBox from './components/ChatBox';
import ChatSessionsList from './components/ChatSessionsList';
import ChatHeader from './components/ChatHeader';
import { ChatProvider, ChatContext } from './ChatContext';
import LoginPage from './pages/LoginPage';

import './App.css';
import './styles.css';
import './Sessions.css';
import './SessionHeader.css';
import './Messages.css';

const Home = () => {
  const [message, setMessage] = useState('');
  const { fetchSessions, isAuthenticated, logout } = useContext(ChatContext);
  const username = localStorage.getItem('username');

  useEffect(() => {
    fetch('/api/test')
      .then(response => response.json())
      .then(data => setMessage(data.message))
      .catch(error => console.error("There was an error!", error));

    fetchSessions();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-container">
          <p className="center">myAIcompanion: {message}</p>
          <nav className="right">
            <ul className="nav-list">
              {isAuthenticated ? (
                <>
                  <li className="welcome-msg">Welcome, {username}!</li>
                  <li className="logout-btn">
                    <button onClick={logout}>Logout</button>
                  </li>
                </>
              ) : (
                <li className="login-btn">
                  <Link to="/login">Login</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <div className="content">
        <div className="chat-sessions">
          <ChatHeader />
          <div className="session-list-container">
            <ChatSessionsList />
          </div>
        </div>
        <div className="chat-box-container">
          <ChatBox />
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(ChatContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch('/api/validate_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.valid) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            alert('Session expired. Please log in again.');
            navigate('/login');
          }
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          alert('Session expired. Please log in again.');
          navigate('/login');
        });
    } else {
      setIsAuthenticated(false);
      navigate('/login');
    }
  }, [setIsAuthenticated, navigate]);

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

const RootApp = () => (
  <Router>
    <ChatProvider>
      <App />
    </ChatProvider>
  </Router>
);

export default RootApp;
